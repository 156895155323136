@import '../../styles/colors';
.resume{
    .header{
        display: flex;
        gap: 10px;
   
        p{
            margin-top: 2px;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .arrays{
        display: flex;
        grid-column-gap: 3em;
        justify-content: space-between;

    }
    .array{
        width: 50%;
        // display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(420px,1fr));
    }
    margin-top: 20px;
    table{
        margin-top: 10px;
        border-collapse: collapse;
        width: 100%;
        thead{
            background: #ccc;
            tr{
                th{
                    padding: 5px;
                    text-align: left;
                    font-weight: 500;
                }
            }
        }
        tr{
            td,th{
                padding: 5px;
            }
            td{
                vertical-align: middle;
                &:nth-child(1),&:nth-child(4){
                    text-align: center;
                }
            }
        }
        tbody{
            tr {
                border-bottom: 2px solid #e9edef ;
                td{
                    padding: 6px;
                  
              
                }
            }
        }
        tfoot{
            background: #b0cbb0;
            tr{
                td{
                   // text-align: left!important;
                    font-weight: 700;
                    &:nth-child(1){
                        padding-left: 10px;
                    }
                    &:nth-child(2){
                       
                    }
                }
            }
        }  
    }
}